import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from '../images/logo.svg';

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <div className="hero-text">
        {/* <img src="https://www.fototransfer.pl/skin/frontend/e-direct-v2/default/images/logo.svg" className="fr" /> */}
        <Link to="/">
          <img src={logo} alt="Fototransfer.pl" style={{ width: `300px`}}/>
          <h1>Informacje dla Partnerów</h1>
        </Link>
        <p>
          <a href="https://fototransfer.pl">Fototransfer.pl</a> dla Twojego biznesu. W tym miejscu znajdziesz
          wszystkie niezbędne materiały.
        </p>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
